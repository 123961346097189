<template>
  <div>
    <div v-if="defaultStyle" v-bind="$attrs" flex="~ items-center" p="x-3.5" h="13" b="1 solid #B3B3B3" rounded="2.5">
      <div w="7" text="center">
        <NuxtIcon :name="selectCityName ? 'my-icon:location' : 'my-icon:search'" text="2xl #8D8D8D" class="align-middle" />
      </div>
      <div m="l-3.5" flex="1" @click="onOpenPopup">
        <span v-if="selectCityName" text="base black" font="500">{{ selectCityName }}</span>
        <span v-else text="base #B1B1B1">{{ t('key.home.search.tips') }}</span>
      </div>
      <NuxtIcon v-if="selectCityName" name="my-icon:close" text="sm" @click="filterConditionStore.filterConditions.selectedCity = {}" />
    </div>
    <div v-else v-bind="$attrs">
      <div text="base white" font-600 leading-30px :style="{ color: 'var(--tc-color-title-list)' }">
        {{ t('key.attractions.list.things.to.do.in') }}
      </div>
      <div mb-10px mb-30px flex flex-nowrap items-center>
        <div text="3xl white" mr-2 truncate font-600 @click="onOpenPopup">
          {{ selectCityName }}
        </div>
        <div h="4" w="4" relative top-3px flex items-center justify-center rounded-full bg-black @click="onOpenPopup">
          <ElIcon><ElIconArrowDownBold color="white" /></ElIcon>
        </div>
      </div>
      <div h-35px rounded-full bg-white flex="~ items-center">
        <div ml-4 text="sm #777474" flex items-center>
          <ElIcon>
            <ElIconSearch color="#777474"></ElIconSearch>
          </ElIcon>
        </div>
        <div p="x-2.5" text="sm #777474" flex="1" font="500" leading="9" class="search-input truncate" @click="onOpenAttrPopup">
          <span v-if="!confirmInput">{{ t('key.search.attrs.placeholder') }}</span>
          <span v-else text="#000">{{ confirmInput }}</span>
          <ElIcon v-if="!!confirmInput" color="#aaa" @click.stop="handleClearSearch">
            <ElIconCircleCloseFilled></ElIconCircleCloseFilled>
          </ElIcon>
        </div>
      </div>
    </div>
  </div>
  <MobileSearchCity v-model="showCityPopup" :loading="loading" :refrehs-on-confirm="refrehsOnConfirm" :page-type="pageType"></MobileSearchCity>
  <MobileSearchAttrs
    v-model="showAttrPopup"
    :loading="loading"
    :refrehs-on-confirm="refrehsOnConfirm"
    :page-type="pageType"
  />
</template>

<script setup lang="ts">
interface Props {
  refrehsOnConfirm?: boolean
  pageType?: string
  defaultStyle?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  refrehsOnConfirm: false,
  pageType: '',
  defaultStyle: true,
})
const { t } = useI18n()
const filterConditionStore = useFilterConditionsStore()
const { confirmInput, clearSearch } = useSearchAttr()
// const isListPage = computed(() => props.pageType === 'list')
const selectCityName = computed(() => {
  return filterConditionStore.filterConditions.selectedCity?.destinationName
})

const canSearchAttr = computed(() => props.pageType !== 'home')

const { showCityPopup } = useUseHomePopup()
const showAttrPopup = ref(false)
const loading = ref(false)

function onOpenPopup() {
  showCityPopup.value = true
}

function onOpenAttrPopup() {
  if (!canSearchAttr.value) {
    onOpenPopup()
    return
  }
  showAttrPopup.value = true
}

function handleClearSearch() {
  clearSearch()
  navigateToList({ replace: true })
}
</script>

<style lang="scss" scoped>
.search-input {
  position: relative;
  padding-right: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .el-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 2rem;
    cursor: pointer;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>
